.start-button {
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  transition: .3s linear;
  box-sizing: border-box;
  justify-content: center;
  //background-color: #2ED573;
  background-color: #16a085;
  box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
  animation: .3s initialAppear linear;

  h1 {
    font-size: 1.2rem;
    color: #000;
  }

  h2 {
    margin-left: 10px;
  }

  svg {
    font-size: x-large;
    margin-right: 10px;
  }

  h1 {
    color: #fff;
    font-size: 1.3rem;
  }
}

.active {
  background-color: #2C5364;
  color: #fff;
  //transition: .3s linear;

  &:hover {
    box-shadow: 0 0 24px -9px #fff;
  }


}


@keyframes shadowBlink {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 24px -9px #fff;
  }

  100% {
    box-shadow: none;
  }
}