@import "./photoAnnotation/_subMenuAnnotation";
@import "./photoAnnotation/_contextAnnotation";

.photo-annotation-wrapper {
  width: 100%;
  height: 100%;
  //overflow-y: auto;
  display: flex;
  position: relative;
  animation: .3s initialAppear linear;

  .image-annotation {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    img {
      width: 100%;
      border-radius: 10px;
    }

    .draw-overlay {
      position: absolute;
      //left: 76px;
      top: 0;
      right: 0;

      .object-rectangle {
        position: absolute;
        border: 3px solid #fff;
        //transition: 0.08s ease;
      }

    }
  }


  .additional-actions {
    box-sizing: border-box;
    display: flex;
    background-color: #f4f4f4;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px;
    width: 56px;

    .additional-actions-icon {
      padding: 15px;
      border-radius: 10px;
      transition: .3s;

      svg {
        width: 25px;
        height: 25px;
        transition: .3s;
      }
    }

    .actions-menu {
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .actions-menu-item {
        svg {
          width: 32px;
          cursor: pointer;
          transition: .3s;

          &:hover {
            padding: 5px;
            color: #f4f4f4;
            border-radius: 10px;
            transform: scale(1.1);
            background-color: #000;
          }
        }

        &:not(:first-child) {
          margin-top: 10px;
        }

        &:last-child {
          margin-top: auto;
          position: relative;
        }
      }


      .toggled-button {
        padding: 5px;
        color: #f4f4f4;
        border-radius: 10px;
        transform: scale(1.1);
        background-color: #000;
      }


      .operator-how-to-content {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(105%, -105%);
        z-index: 1;
        width: 350px;
        background-color: #ffffff;
        animation: .3s initialAppear ease;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 0 0 15px -5px rgba(22, 22, 22, 0.12);

        ul {
          padding: 20px 30px;

          li {
            font-size: .8rem;

            &:not(:first-child) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }


  //h1 {
  //  font-size: .9rem;
  //  transition: .3s;
  //  border-radius: 10px;
  //  cursor: pointer;
  //  color: #fff;
  //  text-align: center;
  //  //&:not(:first-child) {
  //  //  margin-left: 10px;
  //  //}
  //
  //  &:hover {
  //    color: #fff;
  //  }
  //}
  //}
}
