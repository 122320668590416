.sub-menu-annotation {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  /* left: 40%; */
  left: 50%;
  margin-left: -350px;
  top: 25%;
  margin-top: -80px;

  .sub-menu-annotation-objectImage {
    margin-left: 10px;

    img {
      max-width: 150px;
      transition: .3s;

      &:hover {
        transform: scale(1.3);
        box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
      }
    }
  }

  .sub-menu-annotation-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 450px;
    box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);

    h1 {
      font-size: 1.1rem;
      text-align: center;
    }

    .prices-container {
      display: flex;
      justify-content: space-around;

      input {
        width: 50%;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    input {
      border: none;
      padding: 15px;
      border-radius: 10px;
      background-color: #f4f4f4;
      color: #000;
      font-size: .8rem;
      margin-bottom: 5px;
    }

    ul {
      padding: 0;
      max-height: 200px;
      overflow-y: auto;

      li {
        border-radius: 10px;
        list-style-type: none;
        background-color: #f4f4f4;
        margin-bottom: 5px;
        padding: 15px;
        font-size: 1rem;
        transition: .3s ease;
        cursor: pointer;

        &:hover {
          background-color: #2C5364;
        }
      }

      .selected-object {
        background-color: #2C5364;
        color: #fff;
      }
    }

    .sub-menu-annotation-name {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        margin-left: 10px;
        transition: .2s linear;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          //transform: translateX(-5px);
        }
      }
    }
  }


  .sub-menu-annotation-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;

    button {
      background-color: rgb(44, 83, 100);
      color: #F4F4F4;
      font-size: 1rem;
      width: 50%;
      margin: 5px;
      padding: 15px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      outline: none;
      transition: .3s ease;

      &:hover {
        background-color: #16a085;
        box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
      }
    }
  }
}
