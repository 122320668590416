.rect-context-menu {
  background-color: #fff;
  border-radius: 0 10px 10px 10px  ;
  position: absolute;
  overflow: hidden;
  transform: translate(50px, 50px);
  box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);

  .context-menu-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 1rem;
      cursor: pointer;
      transition: .3s ease;
      padding: 0 15px;
      width: 100%;
      text-align: center;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}