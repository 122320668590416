.info-message {
  position: fixed;
  z-index: 100;
  bottom: 40px;
  left: 40px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  border: 5px solid;
  animation: 3s dissmissAnimation ease-in;
  transform: translateX(-1000px);

  h1 {
    font-size: 1.2rem;
    cursor: default;
  }

  svg {
    margin-right: 20px;
  }
}

@keyframes dissmissAnimation {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  10% {
    opacity: 1;
  }
  85% {
    transform: translateX(0);
  }
  95% {
    opacity: .6;
    transform: translateX(-1000px);
  }
}