.custom-magnifier {
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-repeat: no-repeat;
  z-index: 1;
  bottom: 0;
  position: absolute;
  animation: .3s initialAppear ease;


  &:hover {
    .custom-magnifier-center {
      display: none;
    }

    .custom-magnifier-arrows-container {
      display: flex;
    }
  }


  .custom-magnifier-arrows-container {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    animation: .3s initialAppear ease;


  }

  .pos-arrow {
    position: absolute;
    cursor: pointer;

    svg {
      color: #fff;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  //@for $i from 0 through 4 {
  //  //.pos-arrow:nth-child(n + #{$i}) {
  //  //  transform: rotate(90deg * $i);
  //  //}

  .pos-arrow:nth-child(odd) {
    left: calc(50% - 15.75px);
    //left: 50%
  }

  .pos-arrow:nth-child(even) {
    top: calc(50% - 18px);
    //top: 50%;
  }

  //}
}
