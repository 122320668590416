@import '../common/_userInfoHeader.scss';

.user-info-detailed-wrapper {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  animation: .2s initialAppear linear;
  background-color: rgba(26, 26, 26, .6);
}


.user-info-detailed-wrapper-modal {
  max-width: 525px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 600px;
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: .2s ease;
  justify-content: space-between;
  animation: .3s animateDetailedModal ease-in;

  .user-info-stats {
    //flex: 6;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px -10px;
    animation: .6s initialAppear linear;

    .single-stat-wrapper {
      padding: 0 10px;
      display: flex;
      flex-grow: 1;
      flex: 50%;

      .single-stat {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F4F4F4;
        padding: 20px;
        border-radius: 10px;
        margin: 10px 0;
        transition: .3s;

        &:hover {
          box-shadow: 0 0 15px -5px rgba(22, 22, 22, 0.12);
        }


        h1 {
          font-weight: 400;
          font-size: 1.5rem;
        }

        h2 {
          font-size: 1rem;
          font-weight: 400;
        }

      }
    }
  }

  .user-info-companies {
    display: flex;
    align-items: center;
    margin-top: 20px;
    overflow-x: auto;
    animation: .8s initialAppear linear;
    overflow-y: hidden;

    h3 {
      margin-right: 5px;
      cursor: pointer;
      padding: 20px;
      border-radius: 5px;
      opacity: .7;
      transition: .3s;

      //&:hover {
      //  background-color: #f4f4f4;
      //  color: #0F2027;
      //}

      span {
        margin-left: 5px;
      }

      &:hover {
        //box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
        background-color: #f4f4f4;
      }
    }

    .active {
      font-weight: 600;
      opacity: 1;
    }
  }

  .date-joined {
    padding-bottom: 20px;

    h2 {
      font-size: 1.1rem;
    }

    span {
      font-size: 1rem;
    }
  }

  @keyframes leftAppear {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .reload-data-user {
    width: 36px;
    height: 36px;
    cursor: pointer;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    };

    &.loading {
      animation: 1s rotateGear linear infinite;
    }
  }

  .logout-config {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .logout-config-colors {
      display: flex;
      align-items: center;
      animation: .5s leftAppear;
      z-index: 0;
      padding: 15px;
      background-color: #f4f4f4;
      border-radius: 10px;

      .logout-config-colors-single {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        cursor: pointer;
        transition: .3s;

        &:hover {
          box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
          transform: scale(1.1);
        }

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

    }

    .logout-config-button {
      padding: 20px;
      background-color: #f4f4f4;
      border-radius: 10px;
      cursor: pointer;
      transition: .3s;

      &:first-child:hover {
        background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
        box-shadow: #6a11cb;

        svg {
          color: #f4f4f4;
        }
      }

      &:last-child:hover {
        background: linear-gradient(to bottom, #b31217, #e52d27); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        box-shadow: 0 0 20px -3px rgba(192, 57, 43, 0.62);

        svg:not(:root).svg-inline--fa {
          color: #f4f4f4;
        }

      }
    }
  }
}

.toggled {
  width: 720px;
  height: 100%;
  //animation: .3s detailedUserInfo linear;
}

@keyframes animateDetailedModal {
  0% {
    height: 131px;
    width: 400px;
  }
  100% {
    width: 600px;
    height: 754px;
  }
}

//@media only screen and (max-height: 850px) {
//  .user-info-detailed-wrapper-modal {
//    .user-info-stats {
//      .single-stat-wrapper {
//        .single-stat {
//          padding: 10px;
//        }
//      }
//    }
//  }
//}


@keyframes detailedUserInfo {
  from {
    width: 400px;
    height: 15%;
  }
}

@keyframes rotateGear {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes detailedUserInfoFadeIn {
  from {
    background-color: rgba(26, 26, 26, 0);
  }
  to {
    background-color: rgba(26, 26, 26, .6);
  }
}
