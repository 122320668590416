.application-form {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
  //box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);
  min-width: 250px;
  max-width: 400px;
  text-align: center;

  input {
    padding: 15px 10px;
    background-color: #f4f4f4;
    margin: 10px;
    border: none;
    border-radius: 10px;
  }

  button {
    margin: 20px 20px 0 20px;
    padding: 15px 0;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: .3s ease;
    font-size: .9rem;
    &:hover {
      background-color: #2C5364;
      box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);
      color: #f4f4f4;
    }
  }

  p {
    opacity: .6;
    margin-top: 10px;
    cursor: default;
    font-size: .8rem;
  }

  a {
    text-decoration: underline;
    color: #000;
    margin-left: 5px;
  }
}