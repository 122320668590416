.supervisor-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -10px;
  max-height: 100%;
  overflow-y: auto;

  h1 {
    text-align: center;
  }

  .single-operator-wrapper {
    padding: 0 10px;
    flex: 30% 1;
    animation: .3s initialAppear linear;
    //flex: 50% 1;

    .single-operator {
      height: 150px;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #F4F4F4;
      padding: 20px;
      border-radius: 10px;
      margin: 10px 0;
      transition: .3s;
      background-position: center;
      background-size: cover;
      cursor: pointer;


      .single-operator-avatar {
        margin-right: 20px;
        overflow: hidden;

        img {
          width: 80px;
          height: 80px;
          border-radius: 100%;
        }
      }

      .single-operator-info {
        border-left: 1px solid gainsboro;

        h1 {
          margin-left: 20px;
          font-size: 1.3rem;
        }
      }

      .single-operator-stats {
        border-left: 1px solid gainsboro;
        margin-left: auto;

        h1 {
          margin-left: 20px;
        }
      }
    }
  }
}