.user-info-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //align-items: center;

  .user-info-wrapper-logo-name {
    //flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    .user-info-wrapper-name {
      display: flex;
      flex-direction: column;
    }
  }
}

.user-info-wrapper-avatar {
  width: 91px;
  height: 91px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4F4F4;
  overflow: hidden;
  right: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}
