.error-fallback {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #0F2027, #203A43, #2C5364);
  padding: 20px;
  .error-fallback-container {
    background-color: #f4f4f4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
text-align: center;
    h1 {

    }

    .error-fallback-buttons {
      margin-top: 20px;

      button {
        padding: 20px;
        border: none;
        border-radius: 10px;
        margin-right: 10px;
        transition: .2s ease;
        cursor: pointer;
        outline: none;

        &:hover {
          background-color: #2C5364;
          color: #fff;
        }
      }
    }
  }
}