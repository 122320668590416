
.login-page-wrapper {
  width: 100%;
  height: 100vh;
  //background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);
  background-image: linear-gradient(to left, #0F2027, #203A43, #2C5364);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #f4f4f4;

  .login-form-wrapper {
    box-shadow: 0 0 37px -5px rgba(0, 0, 0, 0.75);
    z-index: 2;
    overflow: hidden;
    width: 60%;
    border-radius: 10px;

    min-width: 600px;
    max-width: 800px;
    height: 500px;
    display: flex;
    background-color: #f4f4f4;

    .login-form-left {
      width: 60%;
      background-color: #2C5364;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 20px;
      text-align: center;

      h1, a {
        color: #F4F4F4;
        font-size: 1.3rem;

      }

      svg {
        font-size: 100px;
        align-self: center;
      }

      p {
        font-size: 1.1rem;
        padding: 0 20px;
        color: #fff;
      }

    }
  }

  .login-reg-request-wrapper {
    padding: 20px;
    text-align: center;
    transition: .3s;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      padding: 20px;

      input {
        margin: 5px;
        padding: 10px;
        border: none;
        border-radius: 5px;
        transition: .3s;
        font-size: 1.1rem;
        width: 100%;

        &:focus {
          transform: scale(1.1);
        }
      }

      button {
        padding: 15px 45px;
        border: none;
        background-color: #203A43;
        font-weight: 600;
        color: #fff;
        margin-top: 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.1rem;
        transition: .3s ease;

        &:focus {
          outline: none;
        }

        &:hover {
          transform: scale(1.1);
          background-color: #2C5364;
        }
      }
    }
  }

  .login-page-wave {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(350px);
  }

  .loading {
    animation: 10s animateWave infinite;
  }
}

@media (max-width: 768px) {
  .login-page-wrapper {
    .login-form-wrapper {
      width: 100%;
      min-width: 320px;
      margin: 20px;

      .login-form-left {
        width: 100%;
        background-color: #f4f4f4;

        h1, svg, p, a {
          color: #000;
        }
      }

      .login-reg-request-wrapper {
        display: none;
      }
    }
  }
}

@keyframes animateWave {
  0% {
    transform: translateY(350px);
  }
  30% {
    transform: translateY(50px);
  }
  60% {
    transform: translateY(30px);
  }
  90% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(40px);
  }
}