html {
  scroll-behavior: smooth;
}

.info-page-wrap {
  box-sizing: border-box;

  .mobile-menu {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 69px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    //box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);

    ul {
      list-style-type: none;
      padding: 10px;
      margin-top: -150px;

      li {
        padding: 30px 60px;
        font-size: 1.2rem;
        text-align: center;

        &:active {
          background-color: #f9f9f9;
          border-radius: 10px;
        }
      }
    }
  }

  .sticky-container {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 68px;

    header {
      margin-bottom: 0;
      z-index: 1999;
      position: absolute;
      top: 0;
      width: 100%;
      //border-bottom: rgba(255, 255, 255, .7);
      background-color: #fff;
      box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);
      height: 70px;
      display: flex;
      //display: none;

      //background-color: #f4f4f4;

      .logo-menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        //margin-right: -15px;
        //margin-left: -15px;
        height: 100%;

        .mobile-menu-button {
          padding: 20px;
        }

        .site-logo {
          font-size: 1.5rem;
          cursor: pointer;
        }

        .site-menu {
          ul {
            list-style-type: none;
            display: flex;
            justify-content: flex-end;
            transition: .3s;

            li {
              padding: 25px 20px;
              color: #000;
              text-decoration: none;
              font-size: 1rem;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }

              &:active {
                background-color: #f4f4f4;
              }
            }
          }
        }


      }
    }

    .mobile-nav-menu {
      display: none;
      width: 80%;
      background-color: #16a085;
    }
  }

  .site-cover {
    //overflow: hidden;
    position: relative;
    background-position: center !important;
    background-size: cover !important;

    .site-cover-background {
      position: absolute;
      min-height: 580px;
      height: 65vh;
      box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);
      top: 70px;
      left: 20px;
      right: 20px;
      border-radius: 0 0 15px 15px;
      background-attachment: unset;
      animation: .5s initialAppear;
    }

    .site-cover-content {
      min-height: 80vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 80px;
      padding-bottom: 30px;

      .site-cover-left {
        width: 45%;
        color: #fff;

        h1 {
          font-size: 1.7rem;
          text-align: left;
          line-height: 35px;
        }

        p {
          font-size: 1rem;
        }
      }

      .site-cover-right {
        width: 45%;
        text-align: center;
        //margin-top: 35px;
      }
    }
  }

  .how-it-works {
    .flex-wrap-container {
      margin-bottom: 50px;
    }


    .requirements {
      width: 100%;
      background-color: #2C5364;
      border-radius: 15px;
      overflow: hidden;
      margin-top: 60px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);


      .requirements-wrapper {
        display: flex;
        flex-wrap: wrap;

        .requirements-sub-wrapper {
          margin: 20px;

          .requirement {
            margin: 10px;
            display: flex;
            align-items: center;

            h1 {
              font-size: 1.2rem;
            }

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }


  .reviews-container {
    .customer-review-wrapper {
      height: 330px;
      background-color: #f4f4f4;
      border-radius: 10px;
      overflow: hidden;
      padding: 15px;
      margin: 20px;
      display: flex;
      cursor: pointer;

      .customer-image {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-Repeat: no-repeat;
      }

      .customer-info {
        text-align: justify;
        margin-left: 20px;
        width: 100%;

        h1 {
          text-align: center;
          font-size: 1.4rem;
        }

        h2 {
          font-size: .9rem;
          font-weight: 500;
        }

        p {
          font-size: .8rem;
        }
      }
    }

    //.slick-prev:before {
    //  content: "<";
    //  color: red;
    //  font-size: 30px;
    //}
    //
    //.slick-next:before {
    //  content: ">";
    //  color: red;
    //  font-size: 30px;
    //}

    .slick-slide {
      flex-grow: 1;
    }

    .con {
      margin-top: 0;
      background-color: #f4f4f4;
      padding: 30px 20px;
      border-radius: 15px;
      min-height: 155px;

      h1 {
        margin-bottom: 15px;
        font-size: 1.4rem;
      }
    }

  }

  .special-offers-container {

    .flex-wrap-container {
      justify-content: center;
    }

    .special-offer-wrapper {
      padding: 0 10px;
      margin: 10px 0;

      .special-offer {
        width: 300px;
        height: 100%;
        border-radius: 15px;
        background-color: #f4f4f4;
        padding: 20px;
        text-align: center;
        margin: 10px 0;
        flex-grow: 1;

      }
    }

  }

  footer {
    .footer-background {
      margin-top: 50px;
      padding: 40px;
      min-height: 500px;
      background-color: #f4f4f4;
      position: relative;
      box-shadow: 0 0 31px -15px rgba(0, 0, 0, 0.75);

      .footer-year {
        position: relative;
        z-index: 3;
        text-align: center;
        margin-top: 30px;
        font-size: 1rem;
      }

      .footer-container {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        .footer-contacts {
          margin-top: 30px;
          color: #fff;

          .contacts-wrapper {
            display: flex;
            flex-direction: column;


            .single-contact {
              display: flex;
              align-items: center;

              svg {
                margin-right: 10px;
                color: #fff;
              }

              h1 {
                font-size: 1rem;
                //color: #000;
              }
            }
          }

          .social-wrapper {
            text-align: center;

            svg {
              margin: 5px;
            }
          }
        }

        .footer-form {
          align-self: center;
          text-align: center;


        }
      }

    }
  }
}

.with-background {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.main-cons {
  .con {
    flex-grow: 1;
    margin-top: 50px;

    h1 {
      margin-bottom: 5px;
    }

  }
}

.feature-card-wrapper {
  padding: 0 10px;
  margin: 10px 0;

  .feature-card {
    width: 180px;
    height: 100%;
    background-color: #f4f4f4;
    border-radius: 15px;
    padding: 20px 10px;

    h1 {
      flex-grow: 1;
      margin-top: 20px;
      line-height: 20px;
      font-size: 1rem;
      text-align: center;
    }
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.small-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.site-section {
  padding: 2.5em 0;
  overflow-x: hidden;
}

.section-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

.sub-section-heading {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.application-form-title {
  color: #f4f4f4;
  font-size: 1.2rem;
  line-height: 25px;
  margin-bottom: 20px;
}

.site-text {
  font-size: 1rem;
  text-align: justify;
  line-height: 20px;
}

.light-text {
  color: #ffffff;
  z-index: 1;
}

.dark-text {
  color: #000000;
}

.darken-back {
  position: absolute;
  background-color: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.flex-wrap-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-column-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-col-light {
  background-color: #fff;
}

.slick-slider {
  width: 100%;
}

@media (min-width: 769px) {
  .site-section {
    padding: 5em 0;
  }

  .mobile-menu {
    display: none;
  }
  .mobile-menu-button {
    display: none;
  }

}

@media (max-width: 375px) {

  .info-page-wrap {


    .how-it-works {
      .requirements {
        .requirements-wrapper {
          .requirements-sub-wrapper {
            margin: 0;

            .requirement {
              margin: 0;
            }
          }
        }
      }
    }

  }
  .application-form-title {
    font-size: .9rem;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .feature-card-wrapper {
    .feature-card {
      width: 150px;

      h1 {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}

@media (max-width: 470px) {
  .info-page-wrap {

    .site-cover {
      .site-cover-content {
        .site-cover-right {
          margin: 10px;

          h1 {
            line-height: 20px;
            font-size: .9rem;
          }

          .application-form {
            padding: 20px 10px;

            h1 {
              font-size: 1.2rem;
            }
          }
        }

        .site-cover-left {
          h1 {
            font-size: 1.4rem;
            line-height: 15px;
            margin-bottom: 40px;
          }
        }
      }
    }

    .reviews-container {
      .customer-review-wrapper {
        margin: 0;
        flex-direction: column;
        min-height: 450px;

        .customer-image {
          height: 220px;
        }

        .customer-info {
          margin: 0;
        }
      }
    }


    .special-offers-container {
      .special-offer-wrapper {
        flex-grow: 1;
        margin: 0;

        .special-offer {
          width: 100%;
          height: unset;

        }
      }
    }

    footer {
      .footer-background {
        padding: 20px 0;
      }
    }

    .section-heading {
      font-size: 1.4rem;
    }

    .sub-section-heading {
      font-size: 1.2rem;
      line-height: 20px;
    }
  }
}

@media (max-width: 768px) {
  .info-page-wrap {

    .site-navbar {
      .logo-menu-wrapper {


        .site-menu {
          display: none;
        }
      }
    }

    .site-cover {

      .site-cover-content {
        justify-content: center;
        flex-direction: column;
        min-height: 750px;
        padding-top: 100px;

        .site-cover-left {
          width: 90%;
          padding: 10px;

          h1 {
            line-height: 25px;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

        .site-cover-right {
          width: unset;
          margin: 15px 0;
        }
      }
    }

    .flex-wrap-container {
      justify-content: center;
    }

    .section-heading {
      margin-bottom: 10px;
    }

    footer {
      .footer-container {
        flex-direction: column-reverse;
        align-items: center;


        .footer-contacts {
          padding: 20px;
          border-radius: 10px;

          .contacts-wrapper {
            align-items: center;
          }
        }

        .footer-form {
          width: 100%;
        }
      }
    }

    .special-offers-container {

      .special-offer {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .small-container {
    max-width: 700px;
  }
}
