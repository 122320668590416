.general-info-wrapper {
  height: 35%;
  text-align: center;
  flex: 1;
  overflow-y: hidden;
  position: relative;

  > h1 {
    padding-right: 40px;
  }

  .general-info-wrapper-container {
    margin: 10px -10px;
    overflow-y: auto;
    height: 92%;

    .single-stat-wrapper {
      flex-grow: 1;
      background-color: #f4f4f4;
      margin: 10px;
      border-radius: 10px;

      .single-stat {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        h1 {
          font-size: 1.1rem;
          text-align: center;
          font-weight: 500;
          background-color: transparent;
        }

        h2 {
          font-weight: 500;
        }
      }
    }
  }

  .reload-data-user {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 25px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &.loading {
      animation: 1s rotateGear linear infinite;
    }
  }
}

//@media only screen and (max-height: 900px) {
//  .general-info-wrapper {
//    .general-info-wrapper-container {
//
//      .single-stat-wrapper {
//        width: 25%;
//      }
//    }
//  }
//}
//
//@media only screen and (max-height: 850px) {
//  .general-info-wrapper {
//    .general-info-wrapper-container {
//      overflow-y: scroll;
//
//      .single-stat-wrapper {
//        width: 25%;
//      }
//    }
//  }
//}

//@media only screen and (max-height: 900px) {
//
//  .general-info-wrapper {
//    .general-info-wrapper-container {
//      .single-stat-wrapper {
//        margin: 1px 5px;
//        width: 100%;
//        .single-stat{
//          padding: 5px 25px;
//          flex-direction: row;
//          justify-content: space-between;
//          align-items: center;
//
//          h1{
//            font-size: .9rem;
//          }
//          h2{
//            font-size: .8rem;
//          }
//        }
//      }
//    }
//  }
//
//}
