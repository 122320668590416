.image-placeholder-wrapper {
  padding: 0 10px;
  display: flex;
  flex: 0 0 16.66666%;
  max-width: 16.66666%;
  animation: .3s initialAppear linear;

  .image-placeholder {
    position: relative;
    height: 200px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #F4F4F4;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
    cursor: pointer;

    .ticket-image-overlay {
      position: absolute;
      color: #fff;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: .3s;

      h1 {
        padding: 10px 20px;
        background-color: rgba(54, 54, 54, .7);
        //box-shadow: 0 0 24px -9px rgba(255,255,255, 1);
        border-radius: 10px;
      }

    }

    &:hover {
      .ticket-image-overlay, img {
        transform: scale(1.1);
      }
    }

    img {
      //max-width: 200px;
      transition: .3s;
      width: 100%;
      height: 100%;
    }

    svg {
      width: 40%;
      height: 50px;
    }
  }
}

.img-spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #2C5364;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }

  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }

  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }

  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
}

@media only screen and (max-width: 1600px) {
  .image-placeholder-wrapper {
    flex: 0 0 25%;
    max-width: 25%;
  }
}


@media only screen and (max-width: 1350px) {
  .image-placeholder-wrapper {
    flex: 0 0 25%;
    max-width: 25%;
  }
}


@media only screen and (max-width: 1200px) {
  .image-placeholder-wrapper {
    flex: 0 0 33%;
    max-width: 33%;
  }
}


@media only screen and (max-width: 1000px) {
  .image-placeholder-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}


@media only screen and (max-width: 750px) {
  .image-placeholder-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 200px;
  }
}

