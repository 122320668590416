.user-info-wrapper {
  //height: 15%;
  display: flex;
  cursor: pointer;
  position: relative;
  transition: .3s;
  justify-content: space-between;
}

//@media only screen and (max-height: 900px) {
//  .user-info-wrapper {
//    //min-height: 100px;
//
//    .user-info-header {
//      .user-info-wrapper-logo-name {
//        h1 {
//          font-size: .9rem;
//        }
//      }
//    }
//  }
//
//
//}

