@import 'infoWrapper/_userInfo';
@import 'infoWrapper/_ticketInfo';
@import 'infoWrapper/_generalInfo';
@import 'infoWrapper/_startButton';

.info-wrapper {
  width: 20%;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);
    min-width: 320px;
  }

  .ticket-info-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    //height: 25%;
    overflow-y: auto;

    .ticket-info-wrapper-container {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;


      h1 {
        padding-left: 10px;
        font-size: 1rem;
         margin-bottom: 10px;
      }

      h1:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
