@import "../common/placeholder";
@import "./ticketContent/ticketImage";

.ticket-content-wrapper {
  width: 80%;
  height: 100%;
  //background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 24px -9px rgba(32, 58, 67, 1);

  .placeholders-wrapper {
    //height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -10px -10px;
    max-height: 100%;
    overflow-y: auto;
  }
}