
.ticket-info-wrapper-container {
  display: flex;
  h1 {
    text-align: left;
    background-color: #f4f4f4;
    font-size: 1.1rem;
    border-radius: 10px;


    span {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  //.single-stat-wrapper {
  //  flex-grow: 1;
  //  background-color: #F4F4F4;
  //  margin: 10px;
  //  padding: 10px;
  //  width: 20%;
  //  border-radius: 10px;
  //
  //  .single-stat {
  //    margin: 10px 0;
  //    font-size: 10px;
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: space-between;
  //
  //    h1 {
  //      font-size: .9rem;
  //      line-height: unset;
  //      margin-bottom: 10px;
  //    }
  //  }
  //}
}