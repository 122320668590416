.object-title {
  position: absolute;
  width: 300px;
  padding: 20px;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 10px;
  font-size: 1.1rem;
  text-align: center;
  z-index: 100;

  h1 {
    font-size: 1.1rem;
    line-height: 20px;
    background-color: #F4F4F4;
    padding: 10px;
    border-radius: 10px;
  }

  .object-title-prices {
    display: flex;
    margin-top: 15px;
    width: 100%;
    gap: 10px;

    h2 {
      font-size: 1rem;
      background-color: #F4F4F4;
      padding: 10px;
      border-radius: 10px;
      flex: auto;

      span {
        font-weight: 500;
      }
    }
  }
}
