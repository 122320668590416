@import "./pages/photoAnnotation";
@import "./pages/supervisorContent";
@import "./pages/userInfoDetailed.scss";
@import "./pages/loginPage/_loginPage.scss";
@import "./pages/photoAnnotation/_objectTitle.scss";

@import "./common/_loading.scss";
@import "./common/_infoMessage.scss";
@import "./common/_errorFallback.scss";
@import "common/_magnifier";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  @media (min-width: 320px) {
    font-size: 12px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.app {
  width: 100%;
  height: 100vh;
  background: #000;
  //background: linear-gradient(to left, #0F2027, #203A43, #2C5364);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  opacity: 1;
  animation: .5s initialAppear linear;
}

.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}


h1, .h1 {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: 400;
}

h2, .h2 {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 300;
}

h1, h2, h3 {
  margin: 0;
}

h3 {
  font-size: 1rem;
  font-weight: 300;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ripple {
    margin: 0 auto;
    display: inline-block;
    position: relative;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }


  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}

@keyframes initialAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.service-content {
  width: 100px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-left: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
  box-shadow: inset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.objectRectangleMove{
  text-align: center;
  width: 15px;
  //border: 1px solid;
  //border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -8px;
}